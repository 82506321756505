import * as React from 'react';
import Link, { LinkProps } from 'next/link';

type Props = Omit<LinkProps, 'href'>;

const ResetPasswordLink: React.FunctionComponent<Props> = ({
  children,
  ...props
}) => {
  return (
    <Link {...props} href="/reset">
      {children}
    </Link>
  );
};

ResetPasswordLink.displayName = 'ResetPasswordLink';

export default ResetPasswordLink;
