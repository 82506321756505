import * as React from 'react';
import Link, { LinkProps } from 'next/link';

type Props = Omit<LinkProps, 'href'>;

const SignupLink: React.FunctionComponent<Props> = ({ children, ...props }) => (
  <Link {...props} href={process.env.SIGNUP_URL || ''}>
    {children}
  </Link>
);

SignupLink.displayName = 'SignupLink';

export default SignupLink;
