import * as React from 'react';
import { LoginForm } from '@r360-tours/ui';
import LoginFormLinks from '../login-form-links';
import * as Styled from './login.styled';

interface Props {
  onComplete: () => Promise<void>;
  onPasswordResetRequired: () => Promise<void>;
}

const Login: React.FunctionComponent<Props> = ({
  onComplete,
  onPasswordResetRequired,
}) => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  return (
    <Styled.AnimationContainer className={isProcessing ? 'onprocess' : ''}>
      <Styled.FormWrapper>
        <LoginForm
          onComplete={onComplete}
          onPasswordResetRequired={onPasswordResetRequired}
          setIsProcessing={setIsProcessing}
        />
        {isProcessing || <LoginFormLinks />}
      </Styled.FormWrapper>
    </Styled.AnimationContainer>
  );
};

export default Login;
