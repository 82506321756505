import { UrlObject } from 'url';

export default function getResetRouting({
  required = false,
}: {
  required: boolean;
}): { as?: string; url: UrlObject } {
  return {
    url: {
      pathname: `/reset${required ? '-required' : ''}`,
    },
  };
}
