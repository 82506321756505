import styled from 'styled-components';

export const LoginFormLinks = styled.div`
  text-align: center;
  margin: 0;

  > a {
    display: inline-block;
  }
`;

export const ResetPassword = styled.p`
  margin-top: 1.25rem;
`;

export const SinUpTitle = styled.p`
  margin: 1.5rem auto 0;
`;

export const Form = styled.form`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 18.75rem;
`;

export const Links = styled.p`
  margin-bottom: 0;
  margin-top: 1.5rem;
  text-align: center;
`;
