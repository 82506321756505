import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { event } from '../../lib/gtag';
import { ResetPasswordLink, SignupLink } from '../link';
import * as Styled from './login-form-links.styled';

const handleClick = (): void => {
  event({
    action: 'click_forgot_password',
    category: 'web',
  });
};

const LoginFormLinks = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Styled.LoginFormLinks>
      <Styled.ResetPassword>
        <ResetPasswordLink>
          <a onClick={handleClick}>{t('Login.ForgotPassword')}</a>
        </ResetPasswordLink>
      </Styled.ResetPassword>
      <Styled.SinUpTitle>{t('Login.Signup.Title')}</Styled.SinUpTitle>
      <SignupLink>
        <a id="auth-signup">{t('Login.Signup.Link')}</a>
      </SignupLink>
    </Styled.LoginFormLinks>
  );
};

export default LoginFormLinks;
