import * as React from 'react';
import Link, { LinkProps } from 'next/link';

type Props = Omit<LinkProps, 'href'>;

const LoginLink: React.FunctionComponent<Props> = ({ children, ...props }) => (
  <Link {...props} href="/login">
    {children}
  </Link>
);

LoginLink.displayName = 'LoginLink';

export default LoginLink;
