export const GA_TRACKING_ID =
  process.env.GOOGLE_ANALYTICS_AUTHENTICATOR_TRACKING_ID;

declare global {
  interface Window {
    gtag: (type: string, action: string, details: unknown) => void;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
  method,
  value,
}: {
  action: string;
  category?: string;
  label?: string;
  method?: string;
  value?: string;
}): void => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label || undefined,
    method: method || undefined,
    value: value || undefined,
  });
};
