import styled, { Wordmark as UIWordmark } from '@r360-tours/ui';
import { RICOH360Filled as UILogo } from '@r360-tours/ui/icons';

export const Container = styled.div`
  align-items: center;
  background-color: ${(props): string => props.theme.background.secondary.main};
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;

  @media all and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Logo = styled(UILogo)`
  margin-top: 1.5rem;
  height: 100px;
  width: 100px;
  @media all and (max-width: 768px) {
    height: 54px;
    width: 54px;
  }
`;

export const Wordmark = styled(UIWordmark)`
  height: 47px;
  width: 124px;
  margin: 2rem;
  @media all and (max-width: 768px) {
    height: 25px;
    margin: 0.5rem 1.5rem 1.25rem;
  }
`;
