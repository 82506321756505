import styled from '@r360-tours/ui';

export const Paper = styled.div`
  align-items: center;
  background-color: ${(props): string => props.theme.background.primary.main};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  overflow: auto;
  padding: 2.5rem 0;
  max-width: 1080px;

  @media (min-width: 768px) {
    min-width: 540px;
  }

  @media all and (max-width: 768px) {
    min-width: 250px;
    padding: 1.5rem 1.25rem 1.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    min-width: 380px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
  }
`;
