import * as React from 'react';
import * as Styled from './paper.styled';

const Paper: React.FC = (props) => (
  <Styled.Paper {...props}>
    <Styled.Content>{props.children}</Styled.Content>
  </Styled.Paper>
);

Paper.defaultProps = {};

Paper.displayName = 'Paper';

export default Paper;
