import * as React from 'react';
import { NextPage } from 'next';
import { Auth } from '@r360-tours/core';
import Layout from '../components/layout';
import Login from '../components/login';

interface Props {
  redirectURL: string;
}

const LoginPage: NextPage<Props> = (props) => {
  return (
    <Layout>
      <Login redirectURL={props.redirectURL} />
    </Layout>
  );
};

LoginPage.getInitialProps = async (context) => {
  let redirectURL = process.env.AUTH_DEFAULT_REDIRECT;

  if (typeof context.query.from === 'string') {
    redirectURL = context.query.from;
  }

  if (typeof redirectURL !== 'string') {
    throw new Error('Redirect URL has not been provided.');
  }

  try {
    const session = await Auth.currentSession();
    const isValidSession = session.isValid();

    if (isValidSession && context.res) {
      context.res.writeHead(302, {
        'Cache-Control': 'max-age=0',
        Location: redirectURL,
      });
      context.res.end();
    } else if (isValidSession) {
      window.location.href = redirectURL;
    }
  } catch (error) {
    // Do nothing if there is no session.
  }

  return {
    redirectURL,
  };
};

export default LoginPage;
