import styled from 'styled-components';

export const AnimationContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 250px;
  &.onprocess {
    min-height: 0px;
    transition: min-height 300ms ease;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
