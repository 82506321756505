import * as React from 'react';
import Router from 'next/router';
import getResetRouting from '@r360-tours/core/lib/get-reset-routing';
import { event } from '../../lib/gtag';
import Login from './login';

interface Props {
  redirectURL: string;
}
const LoginContainer: React.FunctionComponent<Props> = ({ redirectURL }) => {
  const onComplete = async () => {
    window.location.href = redirectURL;
    event({ action: 'login' });
  };
  const onPasswordResetRequired = async () => {
    const { url } = getResetRouting({ required: true });
    void Router.replace(url);
  };
  return (
    <Login
      onComplete={onComplete}
      onPasswordResetRequired={onPasswordResetRequired}
    />
  );
};

export default LoginContainer;
