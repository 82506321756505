import * as React from 'react';
import theme from '@r360-tours/ui/core/theme/default';
import Paper from '../paper';
import * as Styled from './layout.styled';

export type Variant = 'default' | 'signup';

const Layout: React.FC = ({ children }) => {
  return (
    <Styled.Container>
      <Styled.Logo color={theme.palette.primary.main} />
      <Styled.Wordmark color={theme.palette.secondary.contrast} />
      <Paper>{children}</Paper>
    </Styled.Container>
  );
};

Layout.defaultProps = {};

Layout.displayName = 'Layout';

export default Layout;
